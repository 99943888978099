import React from "react"
import { navigate } from "gatsby"
import { withFormik, Form, Field } from "formik"
import * as yup from "yup"

import PageIntro from "../pageIntro"
import LinkCog from "../linkCog"

import { handleLogin, isLoggedIn } from "../../utils/auth"

const Login = ({ values, errors, touched, isSubmitting, location }) => {
  if (isLoggedIn()) {
    navigate(`/members/profile`)
  }
  return (
    <>
      <PageIntro
        introTitle="Login"
        introDesc="<p>Access private documents by logging in via the form below.</p>"
      />
      <section className="section sectionWhite">
        <div className="containerFluid">
          <div className="row center-bp1">
            <div className="col-bp1-12 col-bp4-5">
              <Form className="form" name="login">
                <div className="form__group">
                  <label className="form__label" htmlFor="email">
                    Email
                  </label>
                  <Field
                    type="email"
                    className="form__text"
                    name="email"
                    id="email"
                    autoComplete="email"
                  />
                  {errors.email && touched.email ? (
                    <p className="form__error">{errors.email}</p>
                  ) : null}
                </div>
                <div className="form__group">
                  <label className="form__label" htmlFor="password">
                    Password
                  </label>
                  <Field
                    type="password"
                    className="form__text"
                    name="password"
                    id="password"
                    autoComplete="current-password"
                  />
                  {errors.password && touched.password ? (
                    <p className="form__error">{errors.password}</p>
                  ) : null}
                </div>
                {location.state !== null &&
                location.state.errorMessage !== null ? (
                  <p className="form__error" style={{ marginBottom: `1em` }}>
                    Your Email or Password is not valid, please try again.
                  </p>
                ) : null}
                <button
                  className="button button--gold"
                  type="submit"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? `Logging in` : `Log in`}
                  <LinkCog />
                </button>
              </Form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

// Validate our Form component via Formik & yup
const LoginFormik = withFormik({
  mapPropsToValues({ email, password }) {
    return {
      email: "",
      password: "",
    }
  },
  validationSchema: yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required"),
    password: yup.string().required("Password is required"),
  }),
  handleSubmit(values, { props }) {
    handleLogin(values, props)
  },
})(Login)

export default LoginFormik
