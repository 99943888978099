import React from "react"
import { navigate } from "gatsby"

import PageIntro from "../pageIntro"
import { getUser, logout } from "../../utils/auth"
import Documents from "./documents"
import { withFirebase } from "../firebase"

const Profile = props => {
  return (
    <>
      <PageIntro
        introTitle=""
        introDesc="<p>Please find documents available to download from the list below.</p>"
      />

      <section className="section sectionWhite">
        <div className="containerFluid">
          <div className="row">
            <div className="col-bp1-12">
              <p style={{textAlign: `right`, marginBottom: `0`}}>
                Logged in as <i>{getUser().email}</i> &nbsp;| &nbsp;
                <a
                  href="/members/login"
                  onClick={event => {
                    event.preventDefault()
                    logout(() => {
                      // Sign out of user's firebase account
                      props.firebase.doSignOut()
                      // Navigate back to login screen
                      navigate(`/members/login`, { state: { errorMessage: null }})
                    })
                  }}
                >
                  Logout
                </a>
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-bp1-12">
              <Documents categories={props.categories} />
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default withFirebase(Profile)
