import React from "react"
import { StaticQuery, graphql } from "gatsby"

import DownloadCog from "../downloadCog"

export const DocumentItems = (props) => (
  <StaticQuery
    query={graphql`
      query {
        allWpDocument {
          edges {
            node {
              id
              title
              documentCategories {
                nodes {
                  name
                }
              }
              documentDetails {
                documentFile {
                  guid
                }
              }
            }
          }
        }
      }
    `}
    render={(data) => (
      <ul className="downloads downloads--documents">
        {data.allWpDocument.edges
          .filter((document) => {
            // Remove Documents that don't have categories
            return (
              document.node.documentCategories !== undefined &&
              document.node.documentCategories.nodes.length > 0
            )
          })
          .filter((document) => {
            // Filter Documents by our selected Category name
            let foundCategory = document.node.documentCategories.nodes.findIndex(
              (c) => {
                return c.name === props.category
              }
            )
            return foundCategory !== -1
          })
          .map((document, i) => {
            // Now render each Document item
            const url = document.node.documentDetails.documentFile.guid
            return (
              <li key={i} className="download">
                <h3 className="download__title">
                  <a href={url}>{document.node.title}</a>
                </h3>
                <a className="download__cog" href={url}>
                  <DownloadCog />
                </a>
              </li>
            )
          })}
      </ul>
    )}
  />
)

export default DocumentItems
