// Firebase configuration
const config = {
  apiKey: "AIzaSyCFRF6u6huqDkbq8V-JLdqf_Qg374aAFCU",
  authDomain: "prha-13b12.firebaseapp.com",
  projectId: "prha-13b12",
  messagingSenderId: "293316772806",
  appId: "1:293316772806:web:70131d47a0fda3c5a428c7",
}

class Firebase {
  constructor(app) {
    app.default.initializeApp(config)

    /* Firebase APIs */
    this.auth = app.default.auth()
  }

  // *** Auth API ***
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password)

  doSignOut = () => this.auth.signOut()
}

let firebase

function getFirebase(app, auth) {
  if (firebase) {
    return firebase
  }

  firebase = new Firebase(app, auth)
  return firebase
}

export default getFirebase
