import React, { useState, useEffect } from "react"
import { Router } from "@reach/router"
import { graphql } from "gatsby"

import Layout from "../layouts"
import Header from "../components/header"
import PageTitle from "../components/pageTitle"

import PrivateRoute from "../components/members/privateRoute"
import Profile from "../components/members/profile"
import Login from "../components/members/login"

import getFirebase, { FirebaseContext } from "../components/firebase"

const Members = props => {
  // Define Hook which will become our firebase app
  const [firebase, setFirebase] = useState(null)

  useEffect(() => {
    // Assign Firebase app to Hook, this will get passed
    // to login form to authorize login
    const app = import("firebase/app")
    const auth = import("firebase/auth")
    Promise.all([app, auth]).then(values => {
      setFirebase(getFirebase(values[0]))
    })
  }, [firebase])

  return (
    <FirebaseContext.Provider value={firebase}>
      <Layout>
        <div className="page">
          <Header />
          <PageTitle pageTitle="Member's Area" />

          <Router>
            <PrivateRoute
              path="/members/profile"
              component={Profile}
              categories={props.data.allWpDocumentCategory.edges}
            />
            <Login path="/members/login" firebase={firebase} />
          </Router>
        </div>
      </Layout>
    </FirebaseContext.Provider>
  )
}

export default Members

export const MEMBER_PAGE_QUERY = graphql`
  query {
    allWpDocumentCategory {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
