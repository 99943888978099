import React, { useState } from 'react'

import DocumentItems from './documentItems'

const Documents = props => {
  // Hook for our doc categories
  const [showFolders, updateshowFolders] = useState(true)

  // Hook for our doc categories
  const [cat, setCat] = useState(null)

  const handleDocCategory = docCat => {
    setCat(docCat)  
    updateshowFolders(false)
  }

  const handleBackButton = docCat => {
    setCat(null)  
    updateshowFolders(true)
  }

  return (
    <>
      <ul className="document-folders">
        {props.categories.map((cat, i) => {
          return <li key={i} style={{ display: (showFolders) ? `inline-block` : `none` }}>
            <button className="document-folder" onClick={() => handleDocCategory(cat.node.name)}>{cat.node.name}</button>
          </li>
        })}
        <li style={{ display: (showFolders) ? `none` : `inline-block` }} >
          <button className="document-folder__back" onClick={() => handleBackButton()}>Back to folders</button>
        </li>
      </ul>

      <DocumentItems category={cat} />
    </>
  )
}

export default Documents