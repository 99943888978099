import { navigate } from "gatsby"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("gatsbyUser")
    ? JSON.parse(window.localStorage.getItem("gatsbyUser"))
    : {}

const setUser = (user) =>
  window.localStorage.setItem("gatsbyUser", JSON.stringify(user))

const signInAndRedirect = (email, password, firebase) => {
  return firebase
    .doSignInWithEmailAndPassword(email, password) // Use method assigned to our firebase state
    .then(() => {
      // Assign our user and navigate to Private Route
      setUser({ email })
      return navigate(`/members/profile`)
    })
    .catch(function (error) {
      // Show error in console and pass back to form to trigger dialog
      let errorMessage = error.message
      return navigate(`/members/login`, {
        state: { errorMessage },
      })
    })
}

export const handleLogin = ({ email, password }, props) => {
  return signInAndRedirect(email, password, props.firebase)
}

export const isLoggedIn = () => {
  const user = getUser()
  return !!user.email
}

export const logout = (callback) => {
  setUser({})
  callback()
}
